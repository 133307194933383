<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create User</h4>
                        <form ref="form" class="needs-validation" @submit.prevent="createUser">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="validationCustom01">Name</label>
                                        <input
                                                id="validationCustom01"
                                                v-model="name"
                                                type="text"
                                                class="form-control"
                                                placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Username</label>
                                        <input
                                                type="text"
                                                v-model="username"
                                                class="form-control"
                                                placeholder="Username"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="userRole === 'SUPERADMIN'">
                                    <div class="form-group">
                                        <label for="validationCustom03">Country</label>
                                        <multiselect id="validationCustom03" label="country_name" v-model="country" :options="options" :multiple="false" placeholder=" Select country"></multiselect>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Email</label>
                                        <input
                                                type="email"
                                                v-model="email"
                                                class="form-control"
                                                placeholder="Email"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Password</label>
                                        <input
                                                v-model="password"
                                                type="password"
                                                class="form-control"
                                                placeholder="Password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
                <div class="lodader__content" v-if="loader">
                    <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                </div>
                <!-- end card -->
            </div>
        </div>
    </layout>


</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import {axios_post} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Create User",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader, Multiselect },
        data() {
            return {
                options:[],
                value:null,
                loader:false,
                countries:[],
                users:[],
                userRole:"",
                title: "Create User",
                items: [
                    {
                        text: "Users",
                        href: "/users"
                    },
                    {
                        text: "Create User",
                        active: true
                    }
                ],
                password: "",
                username:"",
                country:"",
                name:"",
                email:"",

            };
        },
        methods: {
            createUser(){
                this.loader = true
                console.log(this.users.country_id)
                axios_post("/register",{
                    name:this.name,
                    username:this.username,
                    password:this.password,
                    email:this.email,
                    country_id:this.users.country_id,
                    role:'USER',
                },()=>{
                    window.location.href = '/users'
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                    this.loader = false
                })

            },
            resetForm(){
                this.$refs['form'].reset()
            },
        },
        mounted(){
            this.users=JSON.parse(localStorage.getItem('user'))
            this.userRole=this.users.role
            console.log(this.users)
            console.log(localStorage.getItem('country'))
            this.options=JSON.parse(localStorage.getItem('country')),
            this.options=this.options.sort((a, b)=>{
                if(a.country_name <= b.country_name) return -1;
                else return 1;
            })
            console.log(this.options)

        }
    };

</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>